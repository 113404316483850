export const useLayout = () => {
  /**
  * Header portal height, used to set page content padding top
  */
  const headerPortalHeight = ref(0)

  const preventHeaderTransition = ref(false)

  /**
     * handleHeaderPortalUpdate
     * triggers everytime the component re-renders because the content from the <Portal> changed
     * and updates header portal height.
     */
  const handleHeaderPortalUpdate = (hasContent: boolean, portalElement) => {
    // TODO
    headerPortalHeight.value = hasContent ? portalElement.$el.clientHeight : 0
  }

  return {
    headerPortalHeight,
    preventHeaderTransition,
    handleHeaderPortalUpdate
  }
}
