/**
 * This mixin provides theme property
 * It's not meant to be used directly.
 *
 * @mixin
 */
const THEME_LIGHT = 'light'
const THEME_DARK = 'dark'
const THEME_GREEN = 'green'
const THEME_GREY = 'grey'
const THEME_DARK_GREY = 'dark-grey'

export const useTheme = () => {
  const theme = useState('c-theme', () => THEME_LIGHT)

  const setTheme = (_theme: 'light' | 'dark' | 'green' | 'grey' | 'dark-grey') => {
    theme.value = _theme
  }

  return { theme, setTheme }
}

export const themeProp = {
  theme: {
    type: String,
    default: THEME_LIGHT,
    validator: value => [THEME_LIGHT, THEME_DARK, THEME_GREEN, THEME_GREY, THEME_DARK_GREY].includes(value)
  }
}

export const themeOptions = {
  light: THEME_LIGHT,
  dark: THEME_DARK,
  green: THEME_GREEN,
  grey: THEME_GREY,
  'dark-grey': THEME_DARK_GREY
}
