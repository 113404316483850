<template>
  <div v-if="item.url">
    <a
      :href="item.url"
    >
      <DsTag
        v-if="item[getApiAttr(item).tag]"
        color="green"
        :label="item[getApiAttr(item).tag]"
        class="ds-text-transform-capitalize ds-margin-right-12"
      />
      <ais-highlight
        :attribute="getApiAttr(item).title"
        :hit="item"
        class="ds-heading-xsmall"
      />
      <span
        v-if="item.parameterType"
        class="ds-text-transform-capitalize ds-margin-left-12 ds-color-grey-450"
      >{{ item.parameterType }}</span>
      <span
        v-if="item.parameterRequired"
        class="ds-text-transform-capitalize ds-margin-left-12 ds-color-grey-450 ds-font-weight-bold"
      >required</span>
      <p class="ds-color-green ds-margin-top-8 ds-margin-bottom-12">{{ item.url }}</p>
      <ais-snippet
        attribute="content"
        :hit="item"
      />
    </a>
  </div>
</template>

<script setup>
import { AisSnippet, AisHighlight } from 'vue-instantsearch/vue3/es'
import { DsTag } from 'awds'

const mapApiExplorerAttr = {
  param: { title: 'parameterName', tag: 'callType' },
  endpoint: { title: 'endpointName', tag: 'endpointMethod' },
  overview: { title: 'apiName' }
}

defineProps({
  item: { type: Object, required: true }
})

const getApiAttr = (item) => {
  return mapApiExplorerAttr[item.type]
}

</script>
