<template>
  <ais-infinite-hits>
    <template v-slot="{ items, refineNext, isLastPage }">
      <ul>
        <li
          v-for="item in items"
          :key="item.objectID"
          class="ds-padding-bottom-24 ds-md-padding-bottom-48"
        >
          <div v-if="getUrlValue(item) && !isApiExplorer && !isDocs">
            <a
              :href="getUrlValue(item)"
            >
              <ais-highlight
                :attribute="getTitleAttr(item)"
                :hit="item"
                :class="ds-heading-xsmall"
                class="ds-heading-xsmall"
              />
              <p class="ds-color-green ds-margin-top-8 ds-margin-bottom-12">{{ getPathValue(item) }}</p>
            </a>
            <ais-snippet
              :attribute="getContentAttr(item)"
              :hit="item"
            />
          </div>
          <SearchResultsDocs
            v-if="item.url && isDocs"
            :item="item"
          />
          <SearchResultsApiExplorer
            v-if="item.url && isApiExplorer"
            :item="item"
          />
        </li>
      </ul>

      <button
        v-if="!isLastPage"
        type="button"
        class="ds-button ds-button--primary ds-button--green"
        @click="refineNext"
      >
        {{ localeGlobalContentData.search.showMore }}
      </button>
    </template>
  </ais-infinite-hits>
</template>

<script setup>
import { AisInfiniteHits, AisSnippet, AisHighlight } from 'vue-instantsearch/vue3/es'
import SearchResultsDocs from './SearchResultsDocs.vue'
import SearchResultsApiExplorer from './SearchResultsApiExplorer.vue'
const { localeGlobalContentData } = useGlobalData()

const props = defineProps({
  indexName: { type: String, default: '' }
})

const isApiExplorer = computed(() => {
  return props.indexName.includes('api')
})

const isDocs = computed(() => {
  return props.indexName.includes('docs')
})

// @TODO: Indices attributes will be standardized soon (WIP in collaboration with Docs team)
const getUrlValue = (item) => {
  // Special case for Help
  if (props.indexName.includes('help.adyen.com')) {
    return `https://help.adyen.com${item.url}`
  }
  return item?.url
}

const getTitleAttr = (item) => {
  return item?.h1 ? 'h1' : ''
}

const getContentAttr = (item) => {
  if (item?.pageContent) {
    return 'pageContent'
  }

  if (item?.content || item?._snippetResult?.content) {
    return 'content'
  }

  if (item?._snippetResult?.body_safe) {
    return 'body_safe'
  }

  return ''
}

const getPathValue = (item) => {
  return item?.path || item?.endpointUrl || getUrlValue(item) || item?.section?.full_path || ''
}
</script>
